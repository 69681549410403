/* src/ChatbotContainer.css */

/* Main container */
.chatbot-container {
  display: flex;
  justify-content: space-between;
}
.chatbot-section h3 {
  color: #007bff; /* Set the desired text color */
  font-size: 30px;
}
.response-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow to the card */
  border: 1px solid #d9d9d9; /* Add a border to the card */
  margin-top: 20px; /* Add margin to the top of the card */
}


/* Left section containing chatbot interface */
.chatbot-section {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ccc;
  position: relative;
}

.question-form-container {
  display: flex;
  align-items: center;
  gap: 10px;

}

/* ChatbotContainer.css */

.response-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow to the card */
  border: 1px solid #007bff; /* Add a border to the card */
  margin-top: 20px; /* Add margin to the top of the card */
}

.response-card ul {
  margin: 0; /* Reset margin for the ul element */
  padding-left: 20px; /* Adjust the left padding for the list items */
}

.response-card li {
  margin-bottom: 8px; /* Add margin between list items */
}

/* Info icon styling
.info-icon {
  cursor: pointer;
  font-size: 15px;
  color: #007bff;
  border: 2px solid #007bff;
  border-radius: 50%;
  padding: 5px;
} */


/* Suggestions box styling */
.suggestions-modal {
  position: absolute;
  top: calc(10% + 1px);
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  display: none;
}

.suggestions-modal h3 {
  margin-bottom: 10px;
}

.suggestions-modal ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestions-modal li {
  margin-bottom: 5px;
  cursor: pointer;
  color: #007bff;
  padding: 8px;
  border-radius: 3px;
}

.suggestions-modal li:hover {
  background-color: #b0cce2;
}

/* Show suggestions box when the info icon is clicked or the box itself is hovered */
.chatbot-section:hover .suggestions-modal {
  display: block;
}


/* Input field styling */
input {
  padding: 12px;
  border-color: #007bff;
  border-radius: 10px;
  box-sizing: border-box;
  flex-grow: 1;
}


/* Submit button styling */
button {
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
}

/* Disabled state for the button */
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Response and highlight section */
.response-section {
  margin-top: 20px;
}

/* Styling for response text */
h2 {
  color: #007bff;
  margin-bottom: 10px;
}

/* Styling for the list */
ul {
  list-style-type: none;
  padding: 0;
}

/* Styling for list items */
li {
  margin-bottom: 8px;
}

/* PDF viewer section */
.pdf-viewer-section {
  flex: 1;
  padding: 20px;
}

/* Highlighted text in PDF */
.highlight {
  position: absolute;
  background-color: yellow;
  opacity: 0.5;
}
.response-box {
  background-color: #d4cdcd;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(247, 240, 240, 0.1);
}

.suggested-questions li:hover {
  color: #007bff; /* Set the text color to blue when hovering */
}
